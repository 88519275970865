@import '../../../styles/partials/_settings.scss';

.banner {
  position: relative;
  overflow: hidden;
  .banner-image {
    background-image: url('../../../assets/images/global/landing-bg.jpg');
    background-size: cover;
    height: 100vh;
    width: 100vw;
    min-height: 600px;
    min-width: 100%;
    background-position: right;
    transition: filter 0.5s;
    &.loaded-image {
      filter: blur(30px);
    }
    &.final-transition {
      background-image: url('https://cdn.migsadventure.com/portfolio/images/mobile/m-sequoia-mig.jpg');
      @media (min-width: $small-desktop-width) {
        background-image: url('https://cdn.migsadventure.com/portfolio/images/desktop/sequoia-mig.jpg');
      }
    }
  }
  .banner-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    left: 0;
    right: 0;
    text-align: center;
    h1, h3 {
      font-family: $Orbitron;
      margin: 0 auto;
    }
    h1 {
      font-size: 58px;
    }
    h3 {
      font-size: 40px;
    }
  }
}
