@import '../../../styles/partials/settings';

  .project-preview {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    @media(min-width: $tablet-width) {
      position: relative;
      padding: 0 20px;
    }
    .project-preview-image {
      display: none;
      @media(min-width: $tablet-width) {
        position: absolute;
        display: inline-block;
        width: 49%;
        margin-top: 20px;
        min-height: 480px;
        transition: background-image 0.3s;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .project-info {
      display: inline-block;
      width: 100%;
      text-align: left;
      padding: 20px 20px 30px;
      background-color: rgba(0,0,0,.7);
      max-width: 500px;
      margin: auto;
      @media(min-width: $tablet-width) {
        float: right;
        position: relative;
        margin-top: 20px;
        width: 45%;
        border: solid 1px rgba(255,255,255, 0.2);
        border-radius: 10px;
        min-height: 500px;
        padding: 0 20px 20px;
        max-width: 900px;
      }
      @media(min-width: $small-desktop-width) {
        min-height: 480px;
      }
      @media(min-width: $largest-site-width) {
        min-height: 400px;
      }
      .header, .description > h3 {
          display: none;
        @media(min-width: $tablet-width) {
          display: block;
        }
      }
      .description {
      > p {
        font-size: 18px;
        margin: 0;
        }
        > h3 {
          display: none;
        }
      }
      .resp-list {
        padding-left: 0;
        margin: 10px 0 30px;
      }
      h3 {
        margin-bottom: 0px;
        font-weight: bold;
      }
       p, li {
        font-size: 15px;
         line-height: 1.3;
         margin-top: 8px;
      }
      li {
        margin-bottom: 5px;
        display: inline-block;
        width: 50%;
        list-style-type: none;
        vertical-align: top;
      }
      .cta {
        margin-top: 40px;
        text-align: center;
        @media(min-width: $tablet-width) {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
        }
      }
    }
    &.preview-open {
      max-height: 470px;
      height: auto;
      transition: min-height 0.15s ease-out;
      @media(min-width: $tablet-width) {
        max-height: 100vh;
      }
    }
  }
