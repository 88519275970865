@import '../../../styles/partials/_settings';
@import '../../../styles/partials/_mixins';

   .window-close {
     position: relative;
     z-index: 9999;
     float: right;
     margin-top: 20px;
     margin-right: 20px;
     padding: 5px;
     cursor: pointer;
     .fas {
       font-size: 35px;
       color: rgba(255,255,255, 0.7);
     }
     &:hover {
       .fas {
         color: white;
       }
     }
   }