@import '../../styles/partials/settings';

.contact {
  position: relative;
  padding: 30px 0;
  text-align: center;
  min-height: calc(100vh - 80px);
  background-color: rgba(0,0,0,.1);
  .contact-bg {
    background-image: url('../../assets/images/global/magic-mountains.jpg');
    opacity: 0.2;
    @media(min-width: $tablet-width) {
      //background-image: url('magic-mountains.jpg');
    }
  }
  .contact-header {
    color: white;
    h1 {
      font-size: 36px;
    }
  }
  .contact-form-wrapper {
    position: relative;
    padding: 50px;
    max-width: 650px;
    margin: 20px auto 0;
    border-radius: 3px;
    z-index: 1;
    @media(min-width: $tablet-width) {
      border: solid 1px rgba(255, 255, 255, 0.2);
      margin: 50px auto 0;
      padding: 50px 75px;
      background: rgba(0,0,0,0.5);
    }
    .input-wrapper {
      margin: 10px auto;
      color: white;
      label {
        display: block;
        padding: 10px;
      }
      input, textarea {
        background: rgba(255,255,255,.1);
        outline: none;
        border: rgba(255,255,255,.2) 1px solid;
        padding: 10px;
        width: 100%;
        margin-bottom: 20px;
      }
      textarea {
        min-height: 100px;
        max-height: 450px;
        height: auto;
        width: 100%;
        resize: vertical;
      }
    }
    .submit-btn {
      color: white;
      background: black;
      border: solid 1px rgba(255,255,255, 0.4);
      padding: 10px 20px;
      border-radius: 5px;
      margin-top: 20px;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: all;
      }
    }
  }
}