@import '../../styles/partials/settings';

.about {
  text-align: center;
  position: relative;
  .about-bg {
    //background-image: url('../../assets/images/global/IMG_20190708_233931__01.jpg');
    background-image: url('../../assets/images/global/IMG_20190708_233942.jpg');
    opacity: 0.2;
  }
  .inner-wrapper {
    max-width: 1320px;
    padding: 20px;
    margin: auto;
    min-height: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media(min-width: $tablet-width) {
      align-items: flex-start;
      min-height: 800px;
      padding-top: 150px;
      flex-direction: row;
      justify-content: space-around;
    }
    @media(min-width: $small-desktop-width) {
      min-height: 94vh;

    }
    > div {
      @media(min-width: $tablet-width) {
        width: 45%;
      }
    }
    .about-me, .skills {
      text-align: left;
      width: 100%;
      max-width: 550px;
      display: inline-block;
      @media(min-width: $tablet-width) {
        //width: 49%;
      }
      h1 {
        margin-top: 40px;
        text-align: center;
      }
      &.about-me {

      }
      &.skills {
        max-width: 600px;
        .skills-list {
          padding: 0;
          .items {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
          }
          .item {
            display: inline-block;
            min-width: 145px;
            padding: 20px;
            text-align: center;
            text-shadow:  0px 0px 1px black,
                          0px 0px 2px white,
                          0px 0px 3px black,
                          0px 0px 5px black;
            filter: saturate(.5);
            margin: 2px;
            transition: background-color 1s;
          }
          .slideIn {
            display: inline-block;
            animation: slideIn 3s forwards;
            transform: translate3d(4000 + px, 0, 100px);
            padding: 12px;
            border: 1px solid rgba(255,255,255,0.3);
            @for $i from 1 through 33 {
              &:nth-child(#{$i}n) {
                animation-delay: #{$i * 0.1}s;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    background: blue;
    transform: translate3d(4000 + px, 0, 100px);
  }
  99% {
    background: red;
  }
  100% {
    opacity: 1;
    background: black;
    transform: translate3d(0, 0, 0);
  }
}
