@import '../../../styles/partials/settings';

.projects-filter {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -70px;
  margin-right: 10px;
  min-width: 50px;
  z-index: 1;
  .fa {
    margin-left: 7px;
  }
  .current-filter-item {
    border: 1px solid white;
    border-radius: 8px 8px 0px 8px;
    padding: 5px;
    &:hover {
      border: none;
      background: #1d5de1;
    }
  }
  .nav-filter-items {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    background: #0f0f0f;
    .nav-item {
      padding: 5px;
      &.active, &:hover {
        background: #9e9ff9;
      }
    }
  }
  &:hover {
    border: solid 1px $pale-grey;
    .nav-filter-items {
      max-height: 500px;
      transition: max-height 0.25s ease-in;
      .nav-item {
        &.active {
          background: #1d5de1;
        }
      }
    }
  }
}
