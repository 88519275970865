@import '../../styles/partials/settings';

.projects {
  color: white;
  text-align: center;
  position: relative;
  min-height: 95vh;
  //background-image: url();
  @media (min-width: $tablet-width) {
    text-align: left;
  }
  @media (min-width: $small-desktop-width) {
    //min-height: 800px;
  }
  .projects-bg {
    &.mobile {
      background-image: url('./../../assets/images/mobile/speedway-night.jpeg');
      background-size: 768px;
      background-repeat: repeat-y;
    }
    &.desktop {
      background-image: url('./../../assets/images/desktop/speedway-night.jpeg');
    }
  }
  .project-preview-desktop-wrapper {
    .scroll-projects-active {
      padding-bottom: 230px;
    }
  }
  .projects-wrapper {
    position: relative;
    z-index: 1;
    background-size: cover;
    background-attachment: fixed;
    .arrow {
      position: absolute;
      z-index: 2;
      cursor: pointer;
      font-size: 130px;
      color: rgba(255,255,255,0.7);
      top: 50%;
      transform: translateY(-50%);
      text-outline: black 1px;
      &:hover {
        color: white;
      }
      &.arrow-left {
        left: 30px;
      }
      &.arrow-right {
        right: 30px;
      }
    }
    &.scroll-projects {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: -30px;
      overflow: hidden;
      .inner-projects-wrapper {
        overflow: auto;
        scroll-behavior: smooth;
        &::-webkit-scrollbar {
          display: none;
        }
        &::-moz-viewport-scroll {
          display: none;
        }
        .project-cards {
          position: relative;
          white-space: nowrap;
          width: fit-content;
          min-width: 1200px;
          overflow: hidden;
          @media(min-width: $tablet-width) {
            padding: 0 100px;
          }
          .project-card {
            width: 270px;
            height: auto;
            display: inline-block;
            white-space: normal;
            &.active {
              bottom: 10px;
              width: 280px;
              height: 240px;
              border: solid 2px yellow;
            }
          }
        }
      }
    }
  }
  .header {
    position: relative;
    text-align: left;
    display: inline-block;
    width: 100%;
    background-color: rgba(0,0,0,.5);
    margin-bottom: 20px;
    .title {
      margin-left: 30px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
