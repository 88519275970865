
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scaleZ(2);
  }
  to {
    transform: scaleZ(1);
  }
}

.fadeIn {
  animation: fadeIn 2s forwards;
}

.scaleIn {
  animation: scaleIn 2s forwards;
}
