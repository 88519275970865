@import '../../../styles/partials/_settings';
@import '../../../styles/partials/_mixins';

a.btn, div.btn > a {
  padding: 15px 40px;
  display: inline-block;
  color: white;
}
div.btn.no-url:not(.text) {
  padding: 15px 40px;
}
.btn {
  border: 1px solid rgba(255,255,255,0.3);
  border-radius: 5px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: all 0.2s;
  > .btn-arrow {
    position: absolute;
    font-size: 16px;
    color: inherit;
    display: inline-block;
    vertical-align: text-bottom;
    transition: all 0.3s;
    margin-left: -15px;
    opacity: 0;
  }
  background: rgba(255,255,255,0.1);
  &:hover, &.active {
    background: #0f24dc66;
    padding-left: 20px;
     .btn-arrow {
       margin-left: 7px;
       opacity: 1;
    }
  }
}
