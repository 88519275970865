@import '../../../styles/partials/_settings';
@import '../../../styles/partials/_mixins';

.slider {
  width: 120px;
  z-index: 9;
  position: fixed;
  left: -100%;
  opacity: 0;
  bottom: -10px;
  animation: slide-right;
  animation-duration: 2s;
  animation-delay: 3s;
  animation-fill-mode: forwards;

  .inner {
    .close {
      position: absolute;
      right: -5px;
      top: -5px;
      border-radius: 100%;
      padding: 10px;
      color: black;
    }
    &:after {
      content: '';
      position: absolute;
      top: -22px;
      left: 50%;
      width: 0;
      height: 0;
      border: 32px solid transparent;
      border-top-color: white;
      border-bottom: 0;
      border-left: 0;
      margin-left: 25px;
    }
    .chat-box {
      overflow: hidden;
      border-radius: 10px;
      background: white;
      min-height: 30px;
      height: auto;
      position: absolute;
      //max-height: 185px;
      right: -150px;
      bottom: 195px;
      width: 240px;
      z-index: -1;
      animation: grow;
      animation-duration: 50s;
      animation-fill-mode: both;
      animation-delay: 7s;
      padding: 5px 10px;
      .text {
          top: 0;
          margin: 0;
          z-index: -1;
        color: black;
      }
    }
    .image {
      width: 100%;
    }
  }
}

.hidden {
  margin: 0;
  position: absolute;
  top: 5px;
  left: 10px;
  width: 100%;
  overflow: hidden;
  p {
    z-index: 20;
    position: relative;
    top: 0;
    clear: both;
    margin: 0;
    float: right; /* makes animation go left-to-right */
    width:0; /* graceful degradation: if animation doesn't work, these are invisible by default */
    background: white; /* same as page background */
    animation: typing 2s steps(40, end);
    animation-fill-mode: both;  /* load first keyframe on page load, leave on last frame at end */
    &:nth-child(1) {
      animation-delay: 3s;
      font-weight: bold;
    }
    &:nth-child(2) {
      animation-delay: 7s;
    }
    &:nth-child(3) {
      animation-delay: 8.5s;
    }
    &:nth-child(4) {
      animation-delay: 10s;
    }
    &:nth-child(5) {
      animation-delay: 11.5s;
    }
    &:nth-child(6) {
      animation-delay: 13s;
    }
    &:nth-child(7) {
      animation-delay: 14.5s;
    }
    &:nth-child(8) {
      animation-delay: 16s;
    }
    &:nth-child(9) {
      animation-delay: 17.5s;
    }
    &:nth-child(10) {
      animation-delay: 20s;
    }
  }
}

//animation
@keyframes slide-right {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes grow{
  0% {
    min-height: 30px;
    max-height: 30px;
  }
  //5% {
  //  height: auto;
  //}
  100% {
    //height: 185px;
    max-height: 500px;
  }
}

@keyframes typing {
  from { width: 100%; }
  to { width: 0; }
}
