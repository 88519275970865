@import './partials/_settings';
@import './partials/placeholders';

*, *:before, *:after {
  box-sizing: border-box;
}

//prevents bounce effect on entire window
html {
  overflow: hidden;
  height: 100%;
}
body, #app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-width: 100%;
  position: relative;
  overflow-x: hidden;
  background-color: black;
  color: white;
  @media (min-width: $small-desktop-width) {
    overflow-x: visible;
  }
}
body {
  overflow-x: hidden;
  overflow-y: auto;
  background-color: black;
  color: black;
  margin: auto;
  max-width: $min-4k-width;
  &.no-nav {
    #navbar {
      display: none;
    }
  }
  #main {
    width: 100%;
    text-align: center;
    height: 100%;
    min-height: 100%;
    min-height: stretch;
    .page {
      min-height: 100%;
    }
  }
}

// elements
h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  letter-spacing: 2px;
  font-family: $Bungee;
  color: white;
  text-shadow:
          1px 2px 7px black,
          1px 2px 5px blue;
}
p, label, div {
  line-height: 1.5;
  letter-spacing: 1px;
  font-family: $Saira;
}

a, button {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  font-family: $PressStart2P;
}

.animated {
  display: inline-block;
}

// modifer classes
.disabled {
  //color: $disabled;
  @extend %unselectable;
}

.main-section {
  //padding-top: 30px;
}

.section-bg {
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
