@import '../../../styles/partials/settings';
$image-path: '../../../assets/images/';
$mobile-path: $image-path + 'mobile/';
$desktop-path: $image-path + 'desktop/';
$global-path: $image-path + 'global/';

.project-card {
  color: white;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  width: 90%;
  background-image: url($global-path + 'ncsoft-site-card-logo.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  margin: 10px auto;
  vertical-align: top;
  min-height: 220px;
  cursor: pointer;
  position: relative;
  max-width: 500px;
  background-position: center;
  transition: all 0.2s;
  div, section, h1 {
    pointer-events: none;
  }
  &.inactive {
    filter: sepia(65%);
  }
  @media (min-width: $tablet-width) {
    width: calc(50% - 20px);
    margin: 10px;
  }
  @media(min-width: $small-desktop-width) {
    width: calc(25% - 20px);
    &:hover {
      filter: brightness(130%);
    }
  }
  .title-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    margin: auto;
    left: 0;
    right: 0;
    > h1 {
      font-size: 26px;
      text-shadow: black 1px 1px 2px;
      letter-spacing: 3px;
    }
  }
  .info {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 5px;
    p {
      text-shadow: black 1px 1px 2px;
      display: inline-block;
      &.type {
        float: left;
        text-transform: uppercase;
      }
      &.year {
        float: right;
      }
    }
  }
}
