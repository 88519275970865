.footer {
  text-align: left;
  height: auto;
  background: black;
  background: rgba(255,255,255,0.1);
  padding: 10px 5px;
  p {
    display: none;
    color: white;
    margin-right: 20px;
    text-align: center;
  }
  .fab, .far {
    font-size: 50px;
    margin: 0 7px;
    padding: 5px;
    color: #b3b3b3;
    &:hover {
      color: #e9e7e7;
    }
  }
}